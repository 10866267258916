export const useDevZapp = () => {
  const toast = useToast();
  const baseURL =
    'https://api.devzapp.com.br/funil/start/v2/execute/669c369bf1b34c2773e09ac0';
  const loading = ref(false);

  const sendAppLink = async (args: any) => {
    loading.value = true;
    try {
      await useAsyncData(() =>
        $fetch(baseURL, {
          method: 'post',
          body: args,
        })
      );
      toast.add({
        title: 'Link enviado com sucesso!',
        description: 'Verifique seu celular',
        timeout: 3000,
      });
    } catch (error: any) {
      toast.add({ title: error.data?.message, color: 'red', timeout: 3000 });
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    sendAppLink,
  };
};
